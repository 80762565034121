<script>
// import LegoTooltip from '@/components/Lego/LegoTooltip.vue'
import VSAvatarGroup from "@/components/Tooltip2Vue/VSAvatarGroup.vue";
export default {
  components: {
    // LegoTooltip,
    VSAvatarGroup,
  },
  props: {
    board: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    route() {
      if (this.board) {
        return {
          name: "Tablero",
          params: {
            boardId: this.board.id,
          },
        };
      } else {
        return {
          name: "CrearTablero",
        };
      }
    },
    levelRole() {
      return this.board.roleInBoard.level || 0;
    },
  },
};
</script>

<template>
  <router-link :to="route" class="tablero">
    <slot>
      <template v-if="board">
        <header class="header">
          <!-- badge lego -->
          <vs-button
            class="lego-badge mx-0 py-1"
            transparent
            active
            size="mini"
            :danger="levelRole == 1"
            :info="levelRole == 2"
            :primary="levelRole == 3"
            :success="levelRole == 4"
          >
            <i class="fas fa-circle-user mr-1"></i>
            {{ board.roleInBoard.name }}
          </vs-button>
          <div class="mt-2">
            <span class="title">
              {{ board.title }}
            </span>
          </div>
          <!-- <LegoTooltip /> -->
        </header>
        <div class="body">
          <span class="description">
            <i
              v-show="board.description"
              class="fas fa-arrow-turn-up mr-1 icon-desc"
              style="rotate: 90deg"
            ></i>
            {{ board.description }}
          </span>
        </div>
        <footer>
          <hr />
          <div class="interactions">
            <!-- responsables avatars -->
            <VSAvatarGroup :listAvatars="board.collaborators" />

            <div
              v-b-tooltip
              :title="$moment(board.createdAt).format('DD MMM, YYYY hh:mm a')"
              class="text-white-50"
            >
              <i class="fas fa-calendar-days"></i>
              {{ board.createdAt | moment("from", "now") }}
            </div>
          </div>
        </footer>
      </template>
    </slot>
  </router-link>
</template>

<style scoped>
.tablero {
  min-height: calc(250px * 9 / 16);
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 4px 0.1px var(--lego-shadow);
  background-color: var(--lego-bg);
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), margin 0.3s ease;
}

.tablero:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  .dark & {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.25);
  }
}

.tablero .body {
  flex: 1;
  margin: 0.5rem 0;
}
.description {
  color: rgb(143, 143, 143);
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .tablero .footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.interactions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.3rem;
}

.title {
  font-size: 1.157rem;
  overflow-wrap: break-word;
}

/* .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
} */
</style>
