<script>
import BoardResumen from "@/components/Lego/BoardResumen.vue";
// import AddBtn from "@/components/buttons/AddBtn.vue";
import LegoBoards from "@/components/Lego/LegoBoards.vue";

export default {
  components: {
    BoardResumen,
    // AddBtn,
    LegoBoards,
  },
  async mounted() {
    await this.$store.dispatch("lego/getAndSetBoards");
  },
  computed: {
    boards() {
      return this.$store.state.lego.boards;
    },
    boardsFilter() {
      if (this.inTableros) {
        return this.boards.filter((b) => !b.isTemplate);
      } else {
        return this.boards.filter((b) => b.isTemplate);
      }
    },
    onlyBoards() {
      return this.boards.filter((b) => !b.isTemplate);
    },
    onlyTemplates() {
      return this.boards.filter((b) => b.isTemplate);
    },
  },
  data() {
    return {
      inTableros: true,
    };
  },
};
</script>

<template>
  <div class="inicio">
    <router-view />
    <header class="header">
      <span class="title">LEGOSIS</span>
    </header>

    <div class="secciones">
      <LegoBoards :boards="onlyBoards" title="Tus tableros" hiddenSelect>
        <template #defaultBoard>
          <BoardResumen class="newBoard">
            <div class="newBoard">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                width="52"
                height="52"
                stroke-width="1.2"
              >
                <path
                  d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"
                ></path>
                <path d="M4 9h8"></path>
                <path d="M12 15h8"></path>
                <path d="M12 4v16"></path>
              </svg>
              <h3><b> Crea un tablero</b></h3>
            </div>
          </BoardResumen>
        </template>
      </LegoBoards>
      <LegoBoards :boards="onlyTemplates" title="Plantillas" />

      <!-- se puede agregar link de videos,  -->
      <!-- to do list, notas, donde es responsables  -->
      <!-- analítica, cuantas se han terminado tareas o checklist, -->
    </div>
  </div>
</template>

<style scoped lang="scss">
.inicio {
  padding: 1rem;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
}

.secciones {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.header {
  margin-bottom: 1rem;
}

.header .title {
  font-size: 2rem;
  letter-spacing: 0.25rem;
  font-weight: 700;
  color: var(--lego-gris);
}

.newBoard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: var(--lego-gris);
  font-size: 0.7rem;

  & h3 b {
    color: var(--lego-gris);
  }

  &:hover svg {
    color: var(--primary);
  }
  &:hover h3 b {
    color: #000;
    .dark & {
      color: #fff;
    }
  }
}
</style>
